// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/normalize.css/normalize.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!./scaffolding.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */", "",{"version":3,"sources":["webpack://./frontend/src/Styles/globals.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAKtB,qBAAqB","sourcesContent":["/* stylelint-disable */\n\n@import \"~normalize.css/normalize.css\";\n@import \"scaffolding.css\";\n\n/* stylelint-enable */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
